import { FunctionComponent } from 'react';
import PropTypes from 'prop-types';
// MUI
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { useMediaQuery, useTheme } from '@mui/material';
//local
import { useApi } from '../../context/ApiProvider';
import ProfileBtn from '../ProfileBtn';
import Share from '../Share';
import ShowVersesHeader from '../ShowVersesHeader';
import MenuSideBarHeader from './MenuSideBarHeader';
import ScripturesSelect from '../ScripturesSelect';
import { SMALL_SIZE } from '../../config/params';
import ShowBookHeader from '../ShowBookHeader';

type DefaultHeaderProps = {
  onSelectScripture?: (scripureId: string) => void;
  pending?: boolean;
  disabled?: boolean;
  showSources?: boolean;
  showShare?: boolean;
  showShareVerse?: boolean;
  showVersesExplorer?: boolean;
  showBookHeader?: boolean;
};

const DefaultHeaderPropTypes = {
  onSelectScripture: PropTypes.func,
  pending: PropTypes.bool,
  disabled: PropTypes.bool,
  showSources: PropTypes.bool,
  showShare: PropTypes.bool,
  showShareVerse: PropTypes.bool,
  showVersesExplorer: PropTypes.bool,
  showBookHeader: PropTypes.bool,
};

const DefaultHeader: FunctionComponent<DefaultHeaderProps> = ({
  onSelectScripture,
  pending,
  disabled,
  showSources,
  showShare,
  showShareVerse,
  showVersesExplorer,
  showBookHeader,
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up(SMALL_SIZE));

  const {
    getDrawer: { drawerOn },
  } = useApi();

  return (
    <Box sx={{ backgroundColor: 'white', zIndex: '100' }}>
      <AppBar
        position="static"
        component="nav"
        color="transparent"
        sx={{
          boxShadow: isDesktop ? 'none' : '',
        }}
      >
        <Toolbar
          sx={{ padding: '0px 8px 0px 8px', justifyContent: 'space-between' }}
          disableGutters
        >
          {/*Left Column*/}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {!drawerOn && (
              <MenuSideBarHeader
                style={{
                  justifyContent: 'start',
                  width: 'fit-content',
                }}
              />
            )}

            {showVersesExplorer && <ShowVersesHeader />}
            {showBookHeader && <ShowBookHeader />}
            {showSources && isDesktop && (
              <ScripturesSelect
                disabled={pending || disabled}
                onSelect={onSelectScripture}
              />
            )}
          </Box>

          {/*Right Column*/}
          <Box display={'flex'} alignItems={'center'}>
            {showShare && <Share />}
            {showShareVerse && <Share verse />}
            <ProfileBtn />
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

DefaultHeader.propTypes = DefaultHeaderPropTypes;

export default DefaultHeader;
