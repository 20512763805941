import { memo, type FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';
// MUI
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
// Local
import style from './AboutSlide.module.scss';

type AboutSlideProps = {
  title?: string;
  text?: string;
  blist?: string[];
  // image?: string;
  textColor?: string;
};

const AboutSlidePropTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  blist: PropTypes.array,
  // image: PropTypes.string,
  textColor: PropTypes.string,
};

const AboutSlide: FunctionComponent<AboutSlideProps> = ({
  title,
  text,
  blist,
  // image,
  textColor,
}) => {
  const { t } = useTranslation();

  // <Box m={2} display='flex' justifyContent='center' alignItems='center'>
  //   <img src={image} width='200rem'/>
  // </Box>

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        position: 'absolute',
        top: '0',
        overflowY: 'auto',
        borderRadius: '0.5rem',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          maxWidth: '50rem',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: 'fit-content',
          maxHeight: '100%',
          backdropFilter: 'blur(3px)',
          borderRadius: '0.5rem',
        }}
        className={style['text-box']}
      >
        <Typography
          variant="h5"
          component="div"
          color={textColor ? textColor : 'white'}
          sx={{
            fontWeight: '600',
            textShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)',
          }}
        >
          {title && t(title)}
        </Typography>
        <Typography variant="body1" component="div">
          <Box
            sx={{
              textAlign: 'justify',
              color: textColor ? textColor : 'white',
              textShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)',
              fontSize: '1.2rem',
              fontWeight: '500',
            }}
          >
            {text && t(text)}
          </Box>
          {blist && (
            <List sx={{ listStyleType: 'circle', marginLeft: '2rem', p: 0 }}>
              {map(blist, (item, index) => (
                <ListItem
                  key={index}
                  sx={{
                    display: 'list-item',
                    padding: 0,
                    color: textColor ? textColor : 'white',
                    textShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)',
                    fontSize: '1.2rem',
                    fontWeight: '500',
                  }}
                >
                  {t(item)}
                </ListItem>
              ))}
            </List>
          )}
        </Typography>
      </Box>
    </Box>
  );
};

AboutSlide.propTypes = AboutSlidePropTypes;

export default memo(AboutSlide);
