import { memo, type FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
// MUI
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// Local
import MessageInput from './MessageInput';

const InputSlide: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100%',
        height: '100%',
        backgroundColor: '#fff',
        borderRadius: '0.5rem',
        padding: '0.5rem',
      }}
    >
      <Box
        sx={{
          width: '50rem',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="h3"
          component="div"
          sx={{ color: 'rgba(0 0 0 / 60%)' }}
        >
          {t('home.ask.anything')}
        </Typography>
        <Typography
          variant="h6"
          component="div"
          sx={{ color: 'rgba(0 0 0 / 60%)', pb: '2rem' }}
        >
          {t('home.ask.anything.subtitle')}
        </Typography>
        <MessageInput />
      </Box>
    </Box>
  );
};

export default memo(InputSlide);
