import {
  memo, useState, useCallback,
  type MouseEvent, type FunctionComponent
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// MUI
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import LogoutIcon from '@mui/icons-material/Logout';
import Fade from '@mui/material/Fade';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import Tooltip from '@mui/material/Tooltip';
// Local
import { getRole, logout } from '../helpers/user';
import LoginBtn from './home/LoginBtn';
import SignupBtn from './home/SignupBtn';
import SettingsDlg from './SettingsDlg';
import { Box } from '@mui/material';

type ProfileBtnProps = {
  disabled?: boolean;
}

const ProfileBtnPropTypes = {
  disabled: PropTypes.bool
};

const ProfileBtn: FunctionComponent<ProfileBtnProps> = ({
  disabled
}) => {
  const { t } = useTranslation();
  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  const [mounted, setMounted] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
    setMounted(true);
    setAnchor(null);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
    setMounted(false);
  }, []);

  const handleMouseDown = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  }, []);

  const handleMenuOpen = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchor(event.currentTarget);
  }, []);

  const menuOpen = Boolean(anchor);

  const handleMenuClose = useCallback(() => {
    setAnchor(null);
  }, []);

  const handleMenuLogout = useCallback(() => logout(), []);

  return (
    <>
      {((getRole() == 'user') && (
        <>
          <Tooltip title={t('tooltip.user.profile')} arrow>
            <IconButton onClick={handleMenuOpen} onMouseDown={handleMouseDown} disabled={disabled}>
              <PersonOutlineOutlinedIcon fontSize='large'/>
            </IconButton>
          </Tooltip>
          {mounted && (
            <SettingsDlg open={open} onClose={handleClose}/>
          )}
          <Menu
            id="basic-menu"
            anchorEl={anchor}
            open={menuOpen}
            onClose={handleMenuClose}
            MenuListProps={{ 'aria-labelledby': 'basic-button' }}
            TransitionComponent={Fade}
          >
            <MenuItem onClick={handleOpen}>
              <ListItemIcon>
                <SettingsOutlinedIcon/>
              </ListItemIcon>
              <ListItemText>
                {t('header.menu.settings')}
              </ListItemText>
            </MenuItem>
            <MenuItem onClick={handleMenuLogout}>
              <ListItemIcon>
                <LogoutIcon/>
              </ListItemIcon>
              <ListItemText>
                {t('header.menu.exit')}
              </ListItemText>
            </MenuItem>
          </Menu>
        </>
      )) || (
        <Box sx={{display: 'flex'}}>
          <SignupBtn/>
          <LoginBtn/>
        </Box>
      )}
    </>
  );
};

ProfileBtn.propTypes = ProfileBtnPropTypes;

export default memo(ProfileBtn);
