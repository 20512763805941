import { memo, useCallback, useRef, type FunctionComponent } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperType } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Mousewheel } from 'swiper/modules';
import map from 'lodash/map';
// MUI
import Box from '@mui/material/Box';
// local
import { ABOUT } from '../../config/about';
import AboutSlide from './AboutSlide';
import InputSlide from './InputSlide';
import style from './Carousel.module.scss';
import './Carousel.css';

const Carousel: FunctionComponent = () => {
  const swiperRef = useRef<SwiperType | null>(null);

  const handleSwiper = useCallback((swiper: SwiperType) => {
    swiperRef.current = swiper;
  }, []);

  const handleSlideClick = useCallback((index: number) => {
    swiperRef.current?.slideTo(index);
  }, []);

  const wrapper = (element: React.ReactNode, background?: string) => {
    return (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        {/* <Box
          sx={{
            width: '100%',
            height: '100%',
            backgroundColor: '#fff',
            borderRadius: '0.5rem',
            backgroundImage: background ? `url(${background})` : undefined,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100% auto',
            backgroundPosition: backgroundPosition
              ? backgroundPosition
              : 'center',
            opacity: '0.95',
            overflow: 'hidden',
          }}
        > */}
        {background && (
          <img
            src={background}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              borderRadius: '0.5rem',
            }}
          />
        )}

        {element}
        {/*  </Box> */}
      </Box>
    );
  };

  return (
    <Box className={style['carousel-wrapper']} sx={{}}>
      <Swiper
        slidesPerView={'auto'}
        centeredSlides={true}
        spaceBetween={30}
        mousewheel={{ forceToAxis: true }}
        pagination={{ clickable: true }}
        navigation
        modules={[Mousewheel, Pagination]}
        onSwiper={handleSwiper}
        className="mySwiper"
      >
        <SwiperSlide key={0} onClick={() => handleSlideClick(Number(0))}>
          {wrapper(<InputSlide />)}
        </SwiperSlide>
        {map(ABOUT, ({ title, text, background, blist }, index) => (
          <SwiperSlide
            key={index + 1}
            onClick={() => handleSlideClick(Number(index + 1))}
          >
            {wrapper(
              <AboutSlide title={title} text={text} blist={blist} />,
              background
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default memo(Carousel);
