import { memo, type FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Link } from '@mui/material';
// local
import { ReactComponent as Medium } from '../../images/medium.svg';
import { ReactComponent as XLogo } from '../../images/x-logo.svg';
import { ReactComponent as Youtube } from '../../images/youtube-logo.svg';
import fbLogo from '../../images/Facebook_Logo_Primary.png';

const Footer: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <Box
        display="flex"
        alignItems="flex-start"
        sx={{
          backgroundColor: '#f5f5f5',
          pt: '1rem',
          pb: '3rem',
          width: '90%',
          marginLeft: ' auto',
          marginRight: ' auto',
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          sx={{
            backgroundColor: '#f5f5f5',
          }}
        >
          <Typography color={'rgba(0 0 0 / 50%)'}>
            {t('footer.social.media')}
          </Typography>
          <Link
            href="https://medium.com/@biblum"
            target="_blank"
            rel="noopener noreferrer"
            underline="none"
            py={'1.3rem'}
          >
            <Medium width={60} />
          </Link>
          <Link
            href="https://x.com/biblum_org"
            target="_blank"
            rel="noopener noreferrer"
            underline="none"
          >
            <XLogo width={32} height={32} />
          </Link>
          <Link
            href="https://www.youtube.com/@biblum-org"
            target="_blank"
            rel="noopener noreferrer"
            underline="none"
            sx={{ mt: '-5px' }}
          >
            <Youtube height={80} />
          </Link>
          <Link
            href="https://www.facebook.com/profile.php?id=61565953831776"
            target="_blank"
            rel="noopener noreferrer"
            underline="none"
            sx={{ mt: '-7px' }}
          >
            <img src={fbLogo} width="30rem" />
          </Link>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          sx={{
            pl: '2rem',
            backgroundColor: '#f5f5f5',
          }}
        >
          <Typography color={'rgba(0 0 0 / 50%)'} pb={'1.3rem'}>
            {t('footer.support')}
          </Typography>
          <Box color={'rgba(0 0 0 / 70%)'}>
            {t('footer.email')}
            <Link href="mailto:support@biblum.org" color="inherit">
              support@biblum.org
            </Link>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: '#f5f5f5',
          color: 'white',
          textAlign: 'center',
          p: '1rem',
          pb: '3rem',
          position: 'relative',
          bottom: 0,
          width: '100%',
        }}
      >
        <Typography variant="body1" color="rgba(0 0 0 / 70%)">
          {t('footer.copyright')}
        </Typography>
      </Box>
    </Box>
  );
};

export default memo(Footer);
