import { memo, type FunctionComponent } from 'react';
// MUI
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
// Local imports
import useWidth from '../hooks/useWidth';
import Screen from '../components/Screen';
import Header from '../components/home/Header';
import Footer from '../components/home/Footer';
import Carousel from '../components/home/Carousel';
import Medium from '../components/home/Medium';

const HomePage: FunctionComponent = () => {
  const width = useWidth();
  console.log('useWidth:', width);

  return (
    <Screen>
      <Container
        maxWidth={false}
        disableGutters
        sx={{ backgroundColor: '#f5f5f5', overflowY: 'auto' }}
      >
        <Header />
        <Carousel />
        <Medium />
        <Box sx={{ flexGrow: 1 }} />
        <Footer />
      </Container>
    </Screen>
  );
};

export default memo(HomePage);
